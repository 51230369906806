<template>
  <div>
    <div id="appForm" class="mx-auto col-11 mt-5">
      <div class="flex flexLeft mx-auto contentsWidth">
        <p class="h4 text-center fw-3">{{base.year}}年度 {{base.name}} - 第{{sort}}回進捗状況報告</p>
      </div>
      <hr class="title contentsWidth">

      <div class="notice mx-auto contentsWidth my-4 py-3 px-4">
        <p class="mb-1">・すべての必要項目に入力後、報告を行ってください。</p>
        <p class="mb-1">・数字は半角で入力してください。</p>
        <p class="mb-1">・添付ファイルについては、申請後に閲覧できます。</p>
        <p
          v-if="assets.length > 0"
          class="mb-1">・添付ファイルの原本は以下からダウンロードしてください。</p>
        <p
          v-for="(asset, index) in assets" :key="`openFile_${index}`"
          class="mb-0 ml-5"><b-link
                @click="downloadOpenFile(asset.id, asset.name)">
                  {{asset.name}}
                </b-link></p>

         <p v-show="base.name === '日本人留学生助成'" class="mb-1">
          ＜助成期間＞<br>
          ・1年以上1年半未満の留学：留学終了時に「成果報告書」を提出<br>
          ・1年半以上の留学：留学開始1年後に「進捗状況報告書」<br>
          留学終了時に「成果報告書」を提出
        </p>
        <p v-show="base.name && base.name !== '日本人留学生助成'" class="mb-1">
          ・2年未満：<br>
          研究終了時に「成果報告書」「助成金使途明細書」を提出<br>
          ・2年～2年半：<br>
          １年経過時「進捗状況報告書」「助成金使途明細書」<br>
          助成終了時「成果報告書」「助成金使途明細書」を提出<br>
          ・2年半～3年：<br>
          1年経過時、2年経過時「進捗状況報告書」「助成金使途明細書」<br>
          研究終了時「成果報告書」「助成金使途明細書」を提出
        </p>
        <!-- 調査研究助成のみ表示 -->
        <p v-if="base.name === '調査研究助成' || base.name === '学術調査研究助成'" class="mb-1">
          ＜提出報告書類と提出時期＞
          <br>
          経過年度毎：「進捗状況報告書」、「助成金使途明細書」毎年4月末までに提出
          <br>
          研究終了時：「成果報告書」、「助成金使途明細書「発表論文」終了後1か月以内に提出
        </p>
      </div>

      <div
        class="mb-3 mx-auto contentsWidth">
        <div>
          <v-app-controlview
            :code="base.code"
            :status="interimRep.status"
            :comments="comments"
            :isShowComments="true"/>
        </div>
        <div class="mt-5">
          <div class="col-12 px-0">
            <div>
              <b-row class="mt-2 mx-4 mb-2">
                <div class="label pl-0 mb-0 pr-1 mr-3">
                  <div class="badgeWrap"></div>
                  <div class="labelWrap mb-0">
                    <label class="formLabel mb-0 bold">申請時 {{usecasesLabel[constUsecases.TITLE]}}</label>
                  </div>
                </div>
                <div class="mainWidth">
                  <div class="my-2 ml-1">
                    {{viewUsecase('TITLE', 0)}}
                  </div>
                </div>
              </b-row>
            </div>
            <hr>
          </div>

          <div class="col-12 px-0">
            <div>
              <b-row class="mt-2 mx-4 mb-2">
                <div class="label pl-0 mb-0 pr-1 mr-3">
                  <div class="badgeWrap"></div>
                  <div class="labelWrap mb-0">
                    <label class="formLabel mb-0 bold">申請時 {{usecasesLabel[constUsecases.TERM]}}</label>
                  </div>
                </div>
                <div class="mainWidth">
                  <div class="my-2 ml-1">
                    {{viewUsecase('TERM', 0)}}
                    ~
                    {{viewUsecase('TERM', 1)}}
                  </div>
                </div>
              </b-row>
            </div>
            <hr>
          </div>
          <div
            v-for="(field, index) in fields" :key="index"
            class="col-12 px-0">
            <v-app-field
              :fieldId='field.field_id'
              :fieldLabel="field.label"
              :fieldType="field.type"
              :description="field.description"
              :notice="field.notice"
              :is_required="field.is_required"
              :type="field.type"
              :label="field.label"
              :is_variable="field.is_variable"
              :input_number="field.input_number"
              :min_input_number="field.min_input_number"
              :max_input_number="field.max_input_number"
              :view_template="field.view_template"
              :options.sync="field.options"
              :rules="field.rules"
              :messages="field.messages"
              :params="field.params"
              :writable="writable"
              :downloadFormFile="downloadFormFile"
              :downloadApploadedFile="downloadApploadedFile"
            />
              <!-- :writable="writable"
              :assets="assets"
              :downloadFormFile="downloadFormFile"
              :downloadOpenFile="downloadOpenFile" -->
            <hr>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-4 mb-3 flex flexCenter flexColumn">
        <p class="mb-1 warning">提出後、修正変更はできません。変更がまだある場合は、一時保存をしてください。</p>
      </div>

      <div class="mt-4 mb-3 flex flexCenter contentsWidth">
        <b-button class="btn btn-lg mr-4 bold" to="/top">トップに戻る</b-button>
        <!-- 報告期間内かつ未提出・一時保存の場合のみ表示 -->
        <b-button
          v-if="writable"
          @click="tempSave"
          variant="primary"
          class="btn btn-lg mr-4 bold"
          >一時保存</b-button>
        <b-button
          v-if="writable"
          @click="save"
          variant="primary"
          class="bold mr-4"
          >報告</b-button>
        <!-- <b-button
          v-if="onTime && isSubmited"
          @click="withdraw"
          variant="dark"
          class="btn btn-primary btn-lg bold"
          >報告の削除</b-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import download from '@/modules/download';
import AppControlview from '@/components/gas/parts/Controlview.vue';
import Field from '@/components/parts/application/Field.vue';
import CONST_STATUS from '@/constants/appStatus';
import USECASE from '@/constants/usecase';


export default {
  name: 'gasImterimRepForm',
  components: {
    'v-app-field': Field,
    'v-app-controlview': AppControlview,
  },
  data() {
    return {
      sort: null,
      // 申請データ
      base: {},
      assets: [],
      interimRep: {},
      // 入力欄データ
      fields: {},
      usecases: {},
      usecasesLabel: {},
      constUsecases: USECASE.USECASE,
      writable: false,
      comments: {},
    };
  },
  computed: {
    viewUsecase() {
      return (key, index) => {
        const usecase = this.constUsecases[key];
        if (!this.usecases[usecase] || !this.usecases[usecase][index]) {
          return null;
        }
        return this.usecases[usecase][index].field_answer_text;
      };
    },
    isUnsubmited() {
      return Object.keys(this.interimRep).length === 0 || this.status === CONST_STATUS.APP_STATUS.TEMPSAVE;
    },
    upFileKeys() {
      return this.$store.state.gasApplicationInput.upFileKeys;
    },
    tempUpFileList() {
      return this.$store.state.gasApplicationInput.tempUpFileList;
    },
  },
  methods: {
    async fetchInitData(appId, sort) {
      this.interimRep = {};
      const param = { appId, sort };
      const response = await api.send('/api/interimRep/form', param)
        .catch((err) => {
          console.error(err);
        });
      const { data } = response;
      this.base = data.base;
      this.assets = data.assets;
      this.fields = data.fields;
      this.usecases = data.usecases;
      this.usecasesLabel = data.usecasesLabel;
      this.writable = data.writable;
      if (data.interimRep !== null) {
        this.interimRep = data.interimRep;
        this.comments = data.interimRep.comments;
      }
      const dbDataNoPage = { fields: data.fields, answers: data.answers };
      this.$store.commit('gasApplicationInput/setDbDataNoPage', dbDataNoPage);
    },
    async downloadOpenFile(assetId, assetName) {
      const param = {
        apptypeId: this.base.application_type_id,
        assetId,
        assetName,
      };
      this.$store.dispatch('page/onWaiting');
      const requireBlob = true;
      const response = await api.send('/api/interimRep/download/file', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, assetName);
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        interim_rep_id: this.interimRep.id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      const url = '/api/interimRep/download/uploadedFile';
      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async tempSave() {
      this.pageErrs = [];
      if (!await this.confirm('現在の内容で一時保存をしてもよろしいですか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        sort: this.sort,
        appId: this.base.id,
        apptypeId: this.base.application_type_id,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/interimRep/tempSave', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.$store.commit('gasApplicationInput/initErrMessages');
      await this.alert('一時保存しました。', true);
      window.location.reload();
    },
    async save() {
      if (!await this.confirm('現在の内容で報告をしてもよろしいですか？')) {
        return;
      }
      this.pageErrs = [];
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        sort: this.sort,
        appId: this.base.id,
        apptypeId: this.base.application_type_id,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/interimRep/save', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.$store.commit('userInfo/setErrorMsg', err.response.data.userErrs);
            this.pageErrs = err.response.data.pageErrs;
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.$store.commit('gasApplicationInput/setCode', this.base.code);
      this.$store.commit('gasApplicationInput/setInterimNo', this.sort);
      // リダイレクト
      this.$router.push({ name: 'gasImterimRepEnd', meta: { locate: false } });
    },
    async downloadApploadedFile(uploadedFileKey, index, fileName) {
      const fileKey = this.upFileKeys[uploadedFileKey][index];
      const url = '/api/confupfile/fileDownload';
      this.$store.dispatch('page/onWaiting');
      if (!this.tempUpFileList[uploadedFileKey] || !this.tempUpFileList[uploadedFileKey][fileKey]) {
        const itemNumber = `${uploadedFileKey}-${fileKey}`;
        const tempUpUrl = '/api/confupfile';
        const tempParam = {
          itemNumber: JSON.stringify(itemNumber),
        };
        const { files } = this.$store.state.gasApplicationInput;
        tempParam.file = files[uploadedFileKey];
        const tempResponse = await api.sendForm(tempUpUrl, tempParam, true)
          .catch((err) => {
            console.error(err);
          });
        if (!tempResponse) {
          this.$store.dispatch('page/offWaiting');
          await this.alert('ファイルのダウンロードに失敗しました。再度アップロードしてお試しください。');
          return;
        }
        const saveTempResponse = {
          target: uploadedFileKey,
          subKey: fileKey,
          path: tempResponse.data.path,
        };
        this.$store.commit('gasApplicationInput/saveTempPath', saveTempResponse);
      }
      const path = this.tempUpFileList[uploadedFileKey][fileKey];
      const param = {
        fileName: path,
      };
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.hasChange || to.name === 'gasImterimRepEnd' || this.viewLocateMsg === false) {
      next();
    } else if (await this.confirm('入力内容は破棄されます。このページから移動してもよろしいですか？')) {
      next();
    } else {
      next(false);
    }
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    const { appId, sort } = this.$route.params;
    this.sort = sort;
    await this.fetchInitData(appId, sort);
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  .notice {
    background-color: #F3F3F3;
  }

  .bottonArea {
    width: 1100px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .warning {
    color: #dc3545;
  }
  #appForm {
    display: block;
  }

  .labelWrap {
    display: inline-block;
  }
  .label {
    width: 260px;
    max-width: 260px;
  }
  .formLabel {
    white-space: pre-line;
    position: absolute;
    top: 8px;
  }
  .badgeWrap {
    width: 44px;
    height: 50px;
    display: inline-block;
  }

  .mainWidth {
    width: 730px;
    max-width: 730px;
  }
</style>
